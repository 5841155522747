<template>
  <div class="wallmount" v-if="isVisible && formState == 'idle'">
    <div class="wallmount__grid">
      <h2 class="wallmount__title" v-text="title"></h2>
      <h3 class="wallmount__price">&euro; <span v-html="displayPrice"></span></h3>
      <button
        class="wallmount__item"
        v-for="(wallmount, index) in getSimpleWallmounts"
        :key="index"
        ref="simpleWallmountButtons"
        @click.prevent="setWallmount($event, wallmount, 'simple')"
      >
        <img class="wallmount__image" :src="wallmount.icon" :alt="wallmount.name" />
        <p class="wallmount__name" v-text="wallmount.name"></p>
        <p class="wallmount__set" v-text="'SET of ' + wallmountQuantity"></p>
      </button>

      <button
        class="wallmount__item wallmount__item-large"
        v-if="getComplexWallmount"
        @click.prevent="setWallmount($event, getComplexWallmount, 'complex')"
        ref="complexWallmountButton"
      >
        <img class="wallmount__image-large" :src="getComplexWallmount.icon" :alt="getComplexWallmount.name" />
        <p class="wallmount__name wallmount-ossi" v-html="`1 &times; ${getComplexWallmount.name} ${parseInt(selectedLengthValue)}`"></p>
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: ['title', 'simpleWallmounts', 'complexWallmount', 'nonce', 'locale'],
  data() {
    return {
      isVisible: false,
      formState: 'idle',
      variationButtons: null,
      selectedLengthValue: null,
      lengthInteger: null,
      displayPrice: '0',
      selectedProductId: null,
      selectedProductType: null,
      wallmountQuantity: null,
      selected: null,
      productForm: null,
      wallmountLenghtOffset: 160,
    };
  },
  computed: {
    getSimpleWallmounts() {
      return JSON.parse(this.simpleWallmounts);
    },
    getComplexWallmount() {
      return JSON.parse(this.complexWallmount);
    },
  },
  methods: {
    setWallmount(event, wallmount, type) {
      if (type === 'simple') {
        this.selectedProductId = wallmount.id;
        this.selectedProductType = type;
        this.displayPrice = wallmount.price * this.wallmountQuantity;
      }

      if (type === 'complex') {
        wallmount.variations.forEach((variation) => {
          if (variation.length === this.selectedLengthValue) {
            this.displayPrice = variation.price;
            this.selectedProductId = variation.id;
            this.selectedProductType = type;
          }
        });
      }
      this.setTotalDisplayPrice();
      this.toggleButtonState(event);
    },
    toggleButtonState(e) {
      this.disableButtons();
      e.target.classList.remove('wallmount__opacity');

      if (this.selected == e.target) {
        this.resetValues();
        this.enableButtons();
        this.selected = null;
      } else {
        this.selected = e.target;
      }
    },
    disableButtons() {
      if (this.isVisible) {
        if (this.$refs.complexWallmountButton) {
          this.$refs.complexWallmountButton.classList.add('wallmount__opacity');
        }
        this.$refs.simpleWallmountButtons.forEach((button) => {
          button.classList.add('wallmount__opacity');
        });
      }
    },
    enableButtons() {
      if (this.isVisible) {
        if (this.$refs.complexWallmountButton) {
          this.$refs.complexWallmountButton.classList.remove('wallmount__opacity');
        }
        this.$refs.simpleWallmountButtons.forEach((button) => {
          button.classList.remove('wallmount__opacity');
        });
      }
    },
    setVariation() {
      this.variation = this.variationsJson.find((variation) => {
        return variation.variation_id == this.variationId.value;
      });
    },
    setTotalDisplayPrice() {
      setTimeout(() => {
        const totalPrice = document.querySelector('.current-display-price');
        const productPrice = totalPrice.getAttribute('data-product-price');
        totalPrice.innerText = parseInt(this.displayPrice) + parseInt(productPrice);
      }, 100);
    },
    async submitForm(e) {
      e.preventDefault();

      if (this.selectedProductId != null) {
        const baseUrl = this.locale == 'nl_NL' ? 'https://www.nahka.nl' : 'https://www.nahka.eu';
        // const baseUrl = 'http://nahka.test';
        const res = await axios.post(`${baseUrl}/wp-json/product/add_product`, {
          id: this.selectedProductId,
          qty: this.selectedProductType == 'complex' ? 1 : this.wallmountQuantity,
        });

        if (res.status === 200) {
          this.productForm.submit();
        }
      } else {
        this.productForm.submit();
      }
    },
    variationEventListeners(button) {
      button.addEventListener('click', (e) => {
        this.formState = 'processing';
        this.resetValues();
        this.enableButtons();

        if (e.target.getAttribute('name') == 'attribute_pa_lengte') {
          this.selectedLengthValue = e.target.value;

          if (parseInt(this.selectedLengthValue) > this.wallmountLenghtOffset) {
            this.wallmountQuantity = 3;
          } else {
            this.wallmountQuantity = 2;
          }
        }

        this.formState = 'idle';
      });
    },
    resetValues() {
      this.displayPrice = '0';
      this.selectedProductId = null;
      this.setTotalDisplayPrice();
    },
    init() {
      this.selectedLengthValue = document.querySelector('input[name=attribute_pa_lengte]:checked').value;

      this.isVisible = true;

      if (parseInt(this.selectedLengthValue) > this.wallmountLenghtOffset) {
        this.wallmountQuantity = 3;
      } else {
        this.wallmountQuantity = 2;
      }
    },
  },
  mounted() {
    const loopsButton = document.querySelector('input[value=met-lussen]');
    const noLoopsButton = document.querySelector('input[value=zonder-lussen]');
    const variationButtons = document.querySelectorAll('.variations input');
    const formSubmitButtons = document.querySelectorAll('button[type=submit]');
    this.productForm = document.querySelector('form.variations_form');

    this.init();

    if (loopsButton) {
      loopsButton.addEventListener('click', () => {
        this.isVisible = true;
      });
    }

    if (noLoopsButton) {
      noLoopsButton.addEventListener('click', () => {
        this.isVisible = false;
      });
    }

    variationButtons.forEach((button) => {
      this.variationEventListeners(button);
    });

    formSubmitButtons.forEach((button) => {
      button.addEventListener('click', (e) => {
        this.submitForm(e);
      });
    });
  },
};
</script>
